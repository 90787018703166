import {KpiScore} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Stack, Tooltip, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

type Props = {
  longKilnStoppageCount: number
  longKilnStoppageRcfaCount: number
  lateLongKilnStoppageRcfaCount: number
  overdueKilnStoppageRcfaCount: number
  totalOverdueKilnStoppageRcfaCount: number
}

export const RcfaSummary = ({
  longKilnStoppageCount,
  longKilnStoppageRcfaCount,
  overdueKilnStoppageRcfaCount,
  totalOverdueKilnStoppageRcfaCount,
  lateLongKilnStoppageRcfaCount
}: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const isOverdueWarningShown =
    totalOverdueKilnStoppageRcfaCount > 0 &&
    overdueKilnStoppageRcfaCount !== totalOverdueKilnStoppageRcfaCount
  const overdueVariant = totalOverdueKilnStoppageRcfaCount > 0 ? 'negative' : 'neutral'

  return (
    <Stack direction="row" spacing={3} useFlexGap>
      <KpiScore
        label={t(`${hrocPrefix}.rcfaSummary.label.mandatory`)}
        value={`${longKilnStoppageRcfaCount} / ${longKilnStoppageCount}`}
        data-test-id="mandatory-rcfa"
      />
      <Tooltip
        title={
          isOverdueWarningShown ? (
            <OverdueLabelTooltip
              overdue={overdueKilnStoppageRcfaCount}
              totalOverdue={totalOverdueKilnStoppageRcfaCount}
            />
          ) : (
            ''
          )
        }
        arrow
        placement="right"
      >
        <span>
          <KpiScore
            label={t(`${hrocPrefix}.rcfaSummary.label.overdue`)}
            labelIcon={isOverdueWarningShown ? <InfoOutlined /> : undefined}
            value={totalOverdueKilnStoppageRcfaCount}
            variant={overdueVariant}
            data-test-id="overdue-rcfa"
          />
        </span>
      </Tooltip>
      <KpiScore
        label={t(`${hrocPrefix}.rcfaSummary.label.late`)}
        value={lateLongKilnStoppageRcfaCount}
        data-test-id="late-rcfa"
      />
    </Stack>
  )
}

const OverdueLabelTooltip = ({overdue, totalOverdue}: {overdue: number; totalOverdue: number}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <Stack spacing={0.5}>
      <Typography fontWeight="medium">
        {`${totalOverdue} ${t(`${hrocPrefix}.rcfaSummary.label.overdue`)} (${t(
          `${hrocPrefix}.label.allTime`
        )})`}
      </Typography>
      <Typography>
        {`${overdue} ${t(`${hrocPrefix}.rcfaSummary.label.overdue`)} (${t(
          `${hrocPrefix}.label.last30days`
        )})`}
      </Typography>
    </Stack>
  )
}
