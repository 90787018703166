import type {KilnInfo, HealthIndicatorsApi} from '@hconnect/common/components/kmsStatus'
import {ExpandMore as ExpandMoreIcon} from '@mui/icons-material'
import {Button, MenuItem, Skeleton, Typography, Menu, Stack} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

import {KilnMetricStatuses} from './KilnMetricStatuses'
import {KilnSensorsStatuses} from './KilnSensorsStatuses'

const gridItemSettings = {
  xs: 3,
  md: 2,
  lg: 2
}

export const KilnMonitoring = ({
  isLoading,
  kilns,
  selectedKiln,
  isLoadingHealthIndicators,
  healthIndicators,
  isLoadingSensors,
  sensorAreas,
  onSelectedKilnChange
}: {
  isLoading: boolean
  kilns?: KilnInfo[]
  selectedKiln?: KilnInfo
  isLoadingHealthIndicators: boolean
  healthIndicators?: HealthIndicatorsApi
  isLoadingSensors: boolean
  sensorAreas?: Record<string, string>
  onSelectedKilnChange: (kilnId: string) => void
}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const hasMultipleKilns = !!kilns?.length && kilns.length > 1

  return (
    <Stack spacing={3} sx={{maxHeight: '100%', overflowY: 'auto'}}>
      {hasMultipleKilns && (
        <div>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="text"
            endIcon={<ExpandMoreIcon />}
            sx={{pl: 0, justifyContent: 'flex-start', display: 'flex'}}
            disabled={isLoading}
          >
            <Typography variant="h4" color="text.primary">
              {isLoading && <Skeleton variant="text" width={60} height={30} />}
              {!isLoading
                ? selectedKiln?.name || t(`${hrocPrefix}.kilnMonitoring.label.unnamedKiln`)
                : null}
            </Typography>
          </Button>
        </div>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {kilns?.map((kiln) => (
          <MenuItem
            key={kiln.id}
            selected={kiln.id === selectedKiln?.id}
            onClick={() => {
              onSelectedKilnChange(kiln.id)
              setAnchorEl(null)
            }}
          >
            {kiln.name || kiln.id}
          </MenuItem>
        ))}
      </Menu>

      {selectedKiln && (
        <Stack spacing={3}>
          <KilnMetricStatuses
            isLoading={isLoadingHealthIndicators}
            kilnInfo={selectedKiln}
            healthIndicators={healthIndicators}
            gridItemSettings={gridItemSettings}
          />
          <KilnSensorsStatuses
            sensorAreas={sensorAreas}
            isLoading={isLoadingSensors}
            gridItemSettings={gridItemSettings}
          />
        </Stack>
      )}
    </Stack>
  )
}
