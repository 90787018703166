import {FlexPage} from '@hconnect/common/components/FlexPage'
import {Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {
  KilnMonitoringContainer,
  OperationHoursContainer,
  PlantStatusKpisContainer,
  RcfaContainer,
  WorkOrdersSummaryContainer
} from '../containers'

type PathParameter = {
  plantId?: string
}

export const ReliabilityEngineerDashboard = () => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')

  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <FlexPage title={t(`${hrocPrefix}.reliabilityEngineerDashboard.pageTitle`)}>
      <Stack direction="row" spacing={2} flexGrow={1} useFlexGap flexWrap="wrap">
        <Stack spacing={2} flex={1} useFlexGap>
          <KilnMonitoringContainer plantId={plantId} />
          <WorkOrdersSummaryContainer plantId={plantId} />
        </Stack>
        <Stack spacing={2} flex={1} useFlexGap>
          <PlantStatusKpisContainer plantId={plantId} />
          <Stack direction="row" spacing={2} flex={1} useFlexGap flexWrap="wrap">
            <OperationHoursContainer plantId={plantId} />
            <Stack spacing={2}>
              <RcfaContainer plantId={plantId} />
              {/* <Stack direction="row" spacing={2}>
                TODO: Placeholder for Downtimes widget <CardBox sx={cardBoxSizes['XS']} />
                TODO: Placeholder for My Tasks widget <CardBox sx={cardBoxSizes['XS']} />
              </Stack> */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </FlexPage>
  )
}
