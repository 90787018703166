import {getMaintainPlantKmsUrl} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {RadioButtonChecked} from '@mui/icons-material'
import {Box, Chip, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {CardLinkWrapper, DataContentWrapper, KilnMonitoring} from '../components'
import {cardBoxSizes} from '../consts'
import {useKilnMonitoringState} from '../hooks'

type Props = {
  plantId: string
}

export const KilnMonitoringContainer: React.FC<Props> = ({plantId}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {kilnsInfo, selectedKiln, healthIndicators, sensorAreas, handleSelectedKilnChange} =
    useKilnMonitoringState(plantId)

  const linkPath = getMaintainPlantKmsUrl(plantId, selectedKiln?.id)

  return (
    <CardLinkWrapper href={linkPath} data-test-id="kiln-monitoring-link-to-maintain">
      <CardBox
        sx={{...cardBoxSizes['L'], display: 'flex', flexDirection: 'column'}}
        data-test-id="kiln-monitoring-card"
      >
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3" mb={2}>
              {t(`${hrocPrefix}.kilnMonitoring.cardTitle`)}
            </Typography>
          </Box>
          <Chip
            icon={<RadioButtonChecked />}
            label={t(`${hrocPrefix}.label.live`)}
            size="small"
            sx={{mx: 1}}
          />
        </Box>
        <DataContentWrapper
          isLoading={kilnsInfo.isLoading}
          isError={kilnsInfo.isLoading}
          isRefetching={kilnsInfo.isLoading}
          data={selectedKiln}
          retryFunction={() => void kilnsInfo.refetch()}
          progressSx={{color: 'primary.main'}}
        >
          <KilnMonitoring
            isLoading={kilnsInfo.isLoading}
            kilns={kilnsInfo.data}
            selectedKiln={selectedKiln}
            isLoadingHealthIndicators={healthIndicators.isLoading}
            healthIndicators={healthIndicators.data}
            onSelectedKilnChange={handleSelectedKilnChange}
            isLoadingSensors={sensorAreas.isLoading}
            sensorAreas={sensorAreas.data}
          />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
