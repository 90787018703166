export const cardBoxSizes = {
  XS: {
    width: '158px',
    height: '158px'
  },
  S: {
    width: '332px',
    height: '158px'
  },
  M: {
    minWidth: '332px',
    height: '332px'
  },
  L: {
    minWidth: '680px',
    height: '332px'
  }
}

export const placeholderWrapperSizes = {
  XS: {
    height: '100px'
  },
  S: {
    height: '100px'
  },
  M: {},
  L: {}
}
